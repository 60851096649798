<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- Export Modal -->
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>

    <b-modal
      class="p-2"
      id="confirm-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <img
        class="d-block mx-auto my-3"
        src="../../assets/Depreciation/writeoff_dp.svg"
        alt=""
      />
      <p class="text-center">
        {{ confirmActionText }}
      </p>
      <div class="row justify-content-center gapx-2">
        <button class="cancel-button" @click="$bvModal.hide('confirm-modal')">
          {{ $t("btn.canc") }}
        </button>
        <button
          class="confirm-button confirm-button-red"
          @click="confirmAction"
        >
          {{ $t("btn.conf") }}
        </button>
      </div>
    </b-modal>
    <b-modal
      class="p-2"
      id="reject-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <img
        class="d-block mx-auto my-3"
        src="../../assets/Depreciation/reject.svg"
        alt=""
      />
      <p class="text-center">{{ rejectActionText }}</p>
      <div class="row justify-content-center gapx-2">
        <button class="cancel-button" @click="$bvModal.hide('reject-modal')">
          {{ $t("btn.canc") }}
        </button>
        <button class="confirm-button confirm-button-red" @click="rejectAction">
          {{ $t("btn.conf") }}
        </button>
      </div>
    </b-modal>

    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div
          class="menu d-flex flex-wrap align-items-center justify-content-between my-3"
        >
          <div class="d-flex align-items-center flex-wrap">
            <button
              key="confirm"
              class="btn-outline danger-btn me-3 my-1"
              :disabled="!selectedAssetLength"
              @click="$bvModal.show('confirm-modal')"
            >
              <p>{{ $t("depreciation.confirm_btn") }}</p>
            </button>
            <button
              key="reject"
              class="btn-fill cancel-btn me-3 my-1"
              :disabled="!selectedAssetLength"
              @click="$bvModal.show('reject-modal')"
            >
              <p>{{ $t("depreciation.reject_btn") }}</p>
            </button>

            <div class="position-relative my-2">
              <template v-if="haveDpNotification">
                <div class="pinging"></div>
                <div class="ping"></div>
              </template>
              <b-dropdown id="dropdown-1" :text="actionText" class="m-md-2">
                <b-dropdown-item @click="handleActionClick('CONF_WRITEOFF')">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p>
                      {{ $t("depreciation.conf_writeoff_dropdown") }}
                    </p>
                    <div
                      v-if="getDpNotification.write_off"
                      class="red-dot"
                    ></div>
                  </div>
                </b-dropdown-item>
                <!-- <b-dropdown-item @click="handleActionClick('CONF_PAUSE')">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p>
                      {{ $t("depreciation.conf_pause_dropdown") }}
                    </p>
                    <div
                      v-if="getDpNotification.deactivate"
                      class="red-dot"
                    ></div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item @click="handleActionClick('CONF_ACTIVATE')">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p>
                      {{ $t("depreciation.conf_activate_dropdown") }}
                    </p>
                    <div
                      v-if="getDpNotification.activate"
                      class="red-dot"
                    ></div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item @click="handleActionClick('CONF_CANC_PAUSE')">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p>
                      {{ $t("depreciation.conf_canc_pause_dropdown") }}
                    </p>
                    <div
                      v-if="getDpNotification.cancel_deactivate"
                      class="red-dot"
                    ></div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="handleActionClick('CONF_CANC_ACTIVATE')"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p>
                      {{ $t("depreciation.conf_canc_activate_dropdown") }}
                    </p>
                    <div
                      v-if="getDpNotification.cancel_activate"
                      class="red-dot"
                    ></div>
                  </div>
                </b-dropdown-item> -->
              </b-dropdown>
            </div>
          </div>
          <div class="d-flex">
            <div
              class="search-box d-flex"
              style="border-radius: 10px; margin-right: 10px"
            >
              <img src="@/assets/search-white.svg" width="20px" />
              <input
                type="text"
                v-debounce:700ms="searchByText"
                :placeholder="$t('home.search')"
                v-model="searchText"
              />
            </div>
            <!-- Add -->
            <!-- <button
              class="filter"
              style="border: none"
              type="button"
              id="button-addon2"
              @click="showFilter = true"
            >
              <img src="../../assets/filter-white.svg" />
            </button> -->
            <button
              class="filter filter-disable cursor-not-allowed"
              style="border: none"
              type="button"
              disabled
            >
              <img src="../../assets/filter-white-disable.svg" />
            </button>
          </div>

          <MainAssetFilter
            v-show="showFilter"
            @filterOptions="saveFilter"
            @closeFilter="showFilter = false"
          />
        </div>
        <ConfirmTable
          @selected-assets="setSelectedAsset"
          :assetData="getAllAssets"
          :otherColumn="getAllAssetOtherColumn"
          :page="currentPage"
          :perPage="perPage"
          :menu="action"
        />
        <div class="d-flex justify-content-center mt-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
            @change="loadAssetData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const ACTION = {
  ALL: "ALL",
  CONF_WRITEOFF: "CONF_WRITEOFF",
  CONF_PAUSE: "CONF_PAUSE",
  CONF_ACTIVATE: "CONF_ACTIVATE",
  CONF_CANC_PAUSE: "CONF_CANC_PAUSE",
  CONF_CANC_ACTIVATE: "CONF_CANC_ACTIVATE",
};

import Swal from "sweetalert2";
import axios from "axios";
import { authHeader } from "../../store/actions";
import Loading from "vue-loading-overlay";
import moment from "moment";
import ConfirmTable from "../../components/Table/Depreciation/ComfirmationTable.vue";
import { mapGetters } from "vuex";
import { baseUrl } from "../../util/backend";
import MainAssetFilter from "../../components/MainAssetFilter.vue";
export default {
  name: "WriteoffAsset",
  components: {
    ConfirmTable,
    Loading,
    MainAssetFilter,
  },
  data() {
    return {
      isLoading: true,
      company_id: null,
      selectedAsset: [],
      searchText: "",
      filterOptions: {},
      action: ACTION.CONF_WRITEOFF,
      currentPage: 1,
      totalRows: 1,
      perPage: 50,
      showFilter: false,
    };
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  computed: {
    ...mapGetters({
      getAllAssets: "getAllAssetsStore",
      getAllAssetOtherColumn: "getAllAssetOtherColumn",
      assets_count: "assets_count",
      companyName: "companyName",
      getDpNotification: "getDpNotification",
      haveDpNotification: "haveDpNotification",
    }),
    selectedAssetLength() {
      return this.selectedAsset.length > 0;
    },
    actionText() {
      switch (this.action) {
        case ACTION.CONF_WRITEOFF:
          return this.$t("depreciation.conf_writeoff_dropdown");
        case ACTION.CONF_PAUSE:
          return this.$t("depreciation.conf_pause_dropdown");
        case ACTION.CONF_ACTIVATE:
          return this.$t("depreciation.conf_activate_dropdown");
        case ACTION.CONF_CANC_PAUSE:
          return this.$t("depreciation.conf_canc_pause_dropdown");
        case ACTION.CONF_CANC_ACTIVATE:
          return this.$t("depreciation.conf_canc_activate_dropdown");
        default:
          return "";
      }
    },
    confirmActionText() {
      switch (this.action) {
        case ACTION.CONF_WRITEOFF:
          return this.$t("depreciation.confirm_writeoff_dialog");
        case ACTION.CONF_PAUSE:
          return this.$t("depreciation.confirm_pause_dialog");
        case ACTION.CONF_ACTIVATE:
          return this.$t("depreciation.confirm_activate_dialog");
        case ACTION.CONF_CANC_PAUSE:
          return this.$t("depreciation.confirm_cancel_pause_dialog");
        case ACTION.CONF_CANC_ACTIVATE:
          return this.$t("depreciation.confirm_cancel_activate_dialog");
        default:
          return "";
      }
    },

    successConfirmText() {
      switch (this.action) {
        case ACTION.CONF_WRITEOFF:
          return this.$t("depreciation.success_confirm_writeoff");
        case ACTION.CONF_PAUSE:
          return this.$t("depreciation.success_confirm_pause");
        case ACTION.CONF_ACTIVATE:
          return this.$t("depreciation.success_confirm_activate");
        case ACTION.CONF_CANC_PAUSE:
          return this.$t("depreciation.success_confirm_cancel_activate");
        case ACTION.CONF_CANC_ACTIVATE:
          return this.$t("depreciation.conf_canc_activate_dropdown");
        default:
          return "";
      }
    },

    rejectActionText() {
      switch (this.action) {
        case ACTION.CONF_WRITEOFF:
          return this.$t("depreciation.reject_writeoff_dialog");
        case ACTION.CONF_PAUSE:
          return this.$t("depreciation.reject_pause_dialog");
        case ACTION.CONF_ACTIVATE:
          return this.$t("depreciation.reject_activate_dialog");
        case ACTION.CONF_CANC_PAUSE:
          return this.$t("depreciation.reject_cancel_pause_dialog");
        case ACTION.CONF_CANC_ACTIVATE:
          return this.$t("depreciation.reject_cancel_activate_dialog");
        default:
          return "";
      }
    },

    rejectConfirmText() {
      switch (this.action) {
        case ACTION.CONF_WRITEOFF:
          return this.$t("depreciation.success_reject_writeoff");
        case ACTION.CONF_PAUSE:
          return this.$t("depreciation.success_reject_pause");
        case ACTION.CONF_ACTIVATE:
          return this.$t("depreciation.success_reject_activate");
        case ACTION.CONF_CANC_PAUSE:
          return this.$t("depreciation.success_reject_cancel_pause");
        case ACTION.CONF_CANC_ACTIVATE:
          return this.$t("depreciation.success_reject_cancel_activate");
        default:
          return "";
      }
    },
  },

  methods: {
    handleActionClick(value) {
      switch (value) {
        case ACTION.CONF_WRITEOFF:
          this.action = ACTION.CONF_WRITEOFF;
          break;
        case ACTION.CONF_PAUSE:
          this.action = ACTION.CONF_PAUSE;
          break;
        case ACTION.CONF_ACTIVATE:
          this.action = ACTION.CONF_ACTIVATE;
          break;
        case ACTION.CONF_CANC_PAUSE:
          this.action = ACTION.CONF_CANC_PAUSE;
          break;
        case ACTION.CONF_CANC_ACTIVATE:
          this.action = ACTION.CONF_CANC_ACTIVATE;
          break;
        default:
          break;
      }
      this.loadAssetData();
    },
    async onExport(isMail) {
      this.isLoading = true;
      try {
        const res = await this.$store.dispatch("ExportAsset", {
          is_email: isMail,
          company_id: this.company_id,
          filterOptions: this.filterOptions,
          filename: `${this.$t("asset.as_regdoc")}_${
            this.companyName
          }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`,
          type: "docRegisAsset",
          selected_assets: [],
        });
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    async confirmAction() {
      this.isLoading = true;
      let apiUrl = "";
      let body = {};
      let assetMapID = [];
      switch (this.action) {
        case ACTION.CONF_WRITEOFF:
          assetMapID = this.selectedAsset.map((asset) => asset.id_asset);
          apiUrl = "depreciation/approve/write_off";
          body = {
            id_asset_arr: assetMapID,
          };
          break;
        case ACTION.CONF_PAUSE:
          assetMapID = this.selectedAsset.map((asset) => asset.id_pause_asset);
          apiUrl = "asset/approvepauseasset";
          body = {
            id_pause_asset_list: assetMapID,
          };
          break;
        case ACTION.CONF_ACTIVATE:
          assetMapID = this.selectedAsset.map((asset) => asset.id_pause_asset);
          apiUrl = "asset/approveactivate";
          body = {
            id_pause_asset_list: assetMapID,
          };
          break;
        case ACTION.CONF_CANC_PAUSE:
          assetMapID = this.selectedAsset.map((asset) => asset.id_pause_asset);
          apiUrl = "asset/approvecanceldeactivate";
          body = {
            id_pause_asset_list: assetMapID,
          };
          break;
        case ACTION.CONF_CANC_ACTIVATE:
          assetMapID = this.selectedAsset.map((asset) => asset.id_pause_asset);
          apiUrl = "asset/approvecancelactivate";
          body = {
            id_pause_asset_list: assetMapID,
          };
          break;
        default:
          break;
      }
      try {
        await axios.put(
          `${baseUrl()}${apiUrl}/${this.company_id}`,
          body,
          authHeader()
        );
        Swal.fire({
          icon: "success",
          text: this.successConfirmText,
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
        this.$store.dispatch("getDpNotification");
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.$bvModal.hide("confirm-modal");
      this.isLoading = false;
      this.loadAssetData();
    },
    async rejectAction() {
      let assetMapID = [];
      this.isLoading = true;
      let apiUrl = "";
      let body = {};
      switch (this.action) {
        case ACTION.CONF_WRITEOFF:
          assetMapID = this.selectedAsset.map((asset) => asset.id_asset);
          apiUrl = "depreciation/reject/write_off";
          body = {
            id_asset_arr: assetMapID,
          };
          break;
        case ACTION.CONF_PAUSE:
          assetMapID = this.selectedAsset.map((asset) => asset.id_pause_asset);
          apiUrl = "asset/rejectdeactivate";
          body = {
            id_pause_asset_list: assetMapID,
          };
          break;
        case ACTION.CONF_ACTIVATE:
          assetMapID = this.selectedAsset.map((asset) => asset.id_pause_asset);
          apiUrl = "asset/rejectactivate";
          body = {
            id_pause_asset_list: assetMapID,
          };
          break;
        case ACTION.CONF_CANC_PAUSE:
          assetMapID = this.selectedAsset.map((asset) => asset.id_pause_asset);
          apiUrl = "asset/rejectcanceldeactivate";
          body = {
            id_pause_asset_list: assetMapID,
          };
          break;
        case ACTION.CONF_CANC_ACTIVATE:
          assetMapID = this.selectedAsset.map((asset) => asset.id_pause_asset);
          apiUrl = "asset/rejectcancelactivate";
          body = {
            id_pause_asset_list: assetMapID,
          };
          break;
        default:
          break;
      }
      try {
        await axios.put(
          `${baseUrl()}${apiUrl}/${this.company_id}`,
          body,
          authHeader()
        );
        Swal.fire({
          icon: "success",
          text: this.rejectConfirmText,
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
        this.$store.dispatch("getDpNotification");
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.$bvModal.hide("reject-modal");
      this.isLoading = false;
      this.loadAssetData();
    },

    setSelectedAsset(value) {
      this.selectedAsset = value;
    },

    async searchByText() {
      this.filterOptions.text = this.searchText;
      this.currentPage = 1;
      this.loadAssetData();
    },

    getDpStatus() {
      let status = null;
      switch (this.action) {
        case ACTION.CONF_WRITEOFF:
          status = {
            is_requesting: "write off",
          };
          break;
        case ACTION.CONF_PAUSE:
          status = {
            is_requesting: "deactivate",
          };
          break;
        case ACTION.CONF_ACTIVATE:
          status = {
            is_requesting: "activate",
          };
          break;
        case ACTION.CONF_CANC_PAUSE:
          status = { is_requesting: "cancel deactivate" };
          break;
        case ACTION.CONF_CANC_ACTIVATE:
          status = { is_requesting: "cancel activate" };
          break;
        default:
          break;
      }
      return status;
    },

    async loadAssetData() {
      this.selectedAsset = [];
      this.isLoading = true;
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      /** @type {{ is_requesting: "write off" | "deactivate" | "activate" | "cancel deactivate" | "cancel activate" }} */
      const status = this.getDpStatus();
      if (status.is_requesting == 'write off') {
        await this.$store.dispatch("GetWriteOffList", {
          page: this.currentPage,
          company_id: this.company_id,
          limit: this.perPage,
          mode: 'pending',
          text: this.searchText || undefined,
        });
      } else {
        await this.$store.dispatch("filterAllAsset_pagination", {
          ...status,
          filterOptions: this.filterOptions,
          saveToStore: false,
          countingStatus: this.countingStatus,
          page: this.currentPage,
          company_id: this.company_id,
          limit: this.perPage,
        });
      }
      this.totalRows = this.assets_count;
      this.isLoading = false;
    },
    saveFilter(val) {
      this.showFilter = false;
      this.filterOptions = val;
      if (val.is_clear_filter) {
        this.searchText = "";
      }
      this.searchByText();
    },
  },
  mounted() {
    this.loadAssetData();
  },
};
</script>

<style lang="scss" scoped>
#dropdown-1 {
  width: 300px;
  ::v-deep button {
    outline: none !important;
    // border: 2px solid #d2d2d2 !important;
    border: none;
    background: #e0e0e0 !important;
    color: #000;
    border-radius: 8px;
  }
  p {
    margin: 0px;
    padding: 5px 0;
    font-size: 14px;
  }
}

.red-dot {
  background: #ed1616;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: 15px;
}

input,
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

.btn-fill {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.cancel-btn {
    color: #fff;
    border-color: #7c7c7c;
    background-color: #7c7c7c;
  }
  &:disabled {
    filter: grayscale(1);
    opacity: 0.5;
    cursor: default !important;
  }
}

.btn-outline {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }

  &:disabled {
    filter: grayscale(1);
    opacity: 0.5;
    cursor: default !important;
  }
}

.form-control {
  min-width: 200px;
  width: 200px;
}

.search-box {
  input {
    width: 200px;
    background-color: transparent !important;
    border-radius: 10px;
  }
  img {
    margin: 0 10px;
  }
  background-color: #e0e0e0 !important;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.confirm-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  border-radius: 10px;
  border: none;

  &-red {
    background: #ed1616;
  }
}

.cancel-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: #888;
  border-radius: 10px;
  border: none;
}

/*
  Animation
*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.ping {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: content-box;
  background: #ed1616;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  z-index: 999;
}

.pinging {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: content-box;
  background: rgba(255, 0, 0, 1);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: load 1.5s ease-out infinite;
  z-index: 999;
}

@keyframes load {
  0% {
    transform: scale(1);
    background: rgba(255, 0, 0, 1);
  }
  50% {
    transform: scale(2.5);
    background: rgba(255, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    background: rgba(255, 0, 0, 0);
  }
}
#button-addon2 {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  width: 45px;
  height: 45px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  // border: 1px solid #7c7c7c;
  img {
    width: 40px;
    padding: 0.3rem !important;
  }
}

.filter-disable {
  background: linear-gradient(180deg, #EEEEEE 0%, #AAAAAA 100%);
  width: 45px;
  height: 45px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  // border: 1px solid #7c7c7c;
  img {
    width: 40px;
    padding: 0.3rem !important;
  }
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}
</style>
